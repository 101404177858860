<div  *ngIf="productList$ | observableWithLoading | async as productList"
  [ngClass]="{    loaded: !productList.loading,    'product-slider-wrapper': true  }">
  <div [hidden]="!productList.value">
    <div class="slider-wrapper">
      <div class="title-section" *ngIf="title">
        
        <div class="title-wrapper">
          <h2 class="font-style-semi title">{{ title }}</h2>
          <div class="secondary-title" *ngIf="secondaryTitle">
            {{ secondaryTitle }}
            <a
              *ngIf="linkToMoreUrl?.queryParams"
              class="success-link"
              [routerLink]="'/' + urlTranslateService.routingTable.shop.listing"
              (click)="setAsConstSortStyle()"
              [queryParams]="linkToMoreUrlNoC"
              >{{ secondaryTitleHref }}</a
            >
          </div>
        </div>

        <a *ngIf="linkToMoreText && linkToMoreUrl?.queryParams && !hideMoreLink"
          class="link-to-more text-link"
          [ngClass]="{ 'link-to-more-move-down': moveMoreDown }"
          [routerLink]="'/' + urlTranslateService.routingTable.shop.listing"
          (click)="setAsConstSortStyle()"
          [queryParams]="linkToMoreUrlNoC">
          {{ linkToMoreText }}
        </a>

      </div>
    </div>
    
    <ng-container *ngIf="showSwiper">
    <swiper [config]="swiperOpt" *ngIf="productList?.value?.records?.length" 
      [ngClass]="{
        loaded: swiperIsLoaded,
        wykladziny: allCoverings,
        'no-pagination': !pagination,
        'no-navigation': noNavigation(productList?.value?.records?.length),
        'small-size': cardSize === 'small'
      }"
      class="slider-main"
      (realIndexChange)="activeIndexChange($event)"
      #swiperComponent>

      <ng-template *ngFor="let record of productList?.value?.records; let i = index; let last = last" swiperSlide>
      
        <ng-container *ngIf="productList?.value?.records?.length > 8 && i == productList?.value?.records?.length - 1">
          <a (click)="last ? setAsConstSortStyleLast(record.productLink) : setAsConstSortStyle()" [ngClass]="{ 'see-more': last }">
            <app-single-product 
              [product]="record"
              [height]="height" 
              [width]="width" 
              [lazy]="i > forceLoad">
            </app-single-product>
          </a>
        </ng-container>

        <ng-container *ngIf="productList.value?.records?.length < 8 || i < productList?.value?.records?.length - 1">
          <a (click)="$event.preventDefault(); $event.stopPropagation(); routerGuard($event, record) && setAsConstSortStyle()" 
          [href]="['/' + urlTranslateService.routingTable.shop.product + '/' + record.productLink]">
            <app-single-product
              [product]="record"
              [height]="height"
              [width]="width"
              [cardSize]="cardSize"
              [lazy]="i > forceLoad"
            ></app-single-product>
          </a>
        </ng-container>

      </ng-template>
    </swiper>
    </ng-container>
  </div>

  <ng-container *ngIf="productList.loading">
    <div class="compare-wrapper">
      <div class="empty-state-main">
        <img [src]="'/assets/icons/preloader.gif'" />
      </div>
    </div>
  </ng-container>
</div>
