<div class="tabs-wrapper separator2" *ngIf="hasAllElements(productFamily.products)">
  <div
    class="tab"
    [ngClass]="{ active: activeTab === 'calculator' }"
    (click)="setActiveTab('calculator')"
  >
    <p>{{ "productCalculator.defineSize" | translate }}</p>
  </div>
  <div
    class="tab"
    [ngClass]="{ active: activeTab === 'prepared' }"
    (click)="setActiveTab('prepared')"
  >
    <p>{{ "productCalculator.preparedSizes" | translate }}</p>
    <div class="flag flag-error uppercase tiny-text">
      {{ "productCalculator.cheaper" | translate }}
    </div>
  </div>
</div>
<div
  class="wykladziny-not-unique title"
  *ngIf="hasOnlyNotUniqeElements(productFamily.products)"
>
  <ng-container *ngIf="productFamily.isWalkway">
    {{ "productCalculator.chooseCoverSizeCarpet" | translate }}
  </ng-container>
  <ng-container *ngIf="productFamily.isCovering">
    {{ "productCalculator.chooseCoverSizeCovering" | translate }}
  </ng-container>
</div>

<div
  class="calculator-material"
  *ngIf="
    activeTab === 'calculator' ||
    (activeTab == null && hasOnlyNotUniqeElements(productFamily.products))
  "
>
  <div class="row">
    <p class="font-style-semi">{{ "width" | translate }}</p>
    <app-dropdown
      #sizesDropdown
      [options]="productsInDropdown"
      [optionFieldName]="'option'"
      [isWykladzina]="productFamily.isCovering"
      [productCalc]="true"
      [activeOption]="activeProduct ? activeProduct : 0"
      [dropdownPrice]="true"
      [setToRightBold]="true"
      [additionalInfo]="'promotionPrice'"
      [unit]="productFamily.products[0].priceUnit"
      (selectedItem)="setSelectedItem($event)"
    ></app-dropdown>
  </div>
  <div class="row separator-one-half">
    <p class="font-style-semi">{{ "length" | translate }}</p>
    <app-input-calculator
      suffix=" m"
      [productName]="productFamily.name"
      [defaultOperationValue]="1.0"
      [value]="selectedHeight"
      (valueChange)="updateSelectedHeight($event)"
    ></app-input-calculator>
  </div>

  <div class="checkbox-wrapper separator2" *ngIf="bindingProductFamily">
    <div
      class="flex-wrapper-checkbox separator1"
      (click)="toggleOrderBinding()"
    >
      <input
        type="checkbox"
        class="primary-checkbox"
        [checked]="orderBindingActive"
      />
      <span class="checkmark"></span>
      <p class="font-style-semi">
        {{ "productCalculator.wantOrderBinding" | translate }} ({{ bindingProductFamily.lowsetPromotionPrice.toString().replace(".", ",") }}{{ "general.currency" | translate }}/m)
      </p>
    </div>

    <div *ngIf="orderBindingActive" class="toggle-wrapper" (click)="toggleOrderAllBinding()">
      <div class="toggle" [ngClass]="{ 'active-toggle': orderAllBindingActive }"></div>
      <p class="font-style-semi" *ngIf="!productFamily.isCovering">
        {{ "productCalculator.twoBindingOrder" | translate }}
      </p>
      <p class="font-style-semi" *ngIf="productFamily.isCovering">
        {{ "productCalculator.allBindingOrder" | translate }}
      </p>
    </div>

    <div class="wrapper-binding" [ngClass]="{ hide: !orderBindingActive }">
      <div class="relative">
        <input
          class="input"
          [ngClass]="{ disabled: !orderBindingActive || orderAllBindingActive }"
          [disabled]="!orderBindingActive || orderAllBindingActive"
          [value]="valueBindingFormated"
          id="'bindingSize'"
          placeholder="'Długość obszycia'"
          (blur)="updateValueBinding(valueBindingInput.value)"
          (keyup)="updateValueBindingByEnter(valueBindingInput.value, $event)"
          #valueBindingInput
        />
        <label
          (click)="valueBindingInput.focus()"
          class="form-label"
          for="'bindingSize'"
          >{{ "cart.heightBinding" | translate }}</label
        >
      </div>
      <p>
        {{ "productCalculator.whenBindingUnusual" | translate }}
        <a href="#" class="success-link" (click)="showIntercom()">{{
          "productCalculator.whenBindingUnusual2" | translate
        }}</a>
      </p>
    </div>
  </div>

  <div class="separator-gray inactive"></div>
  <div class="summary-price-wrapper">
    <span class="color-body-text">
      {{ getQuantity() | valueWithComma : true}}
      <ng-container *ngIf="!productFamily.isCovering">m</ng-container>
      <ng-container *ngIf="productFamily.isCovering">m&#178;</ng-container>
      <b> x</b> {{ selectedItem?.promotionPrice }}
      <span [innerHTML]="productFamily.products[0].priceUnit"></span>
      <span *ngIf="orderBindingActive">
        <b> +</b>
        {{ valueBinding * bindingProductFamily.lowsetPromotionPrice | valueWithComma }} {{ "general.currency" | translate }}/{{ "binding" | translate }}
      </span>
    </span>
    <p class="font-style-semi heading3 summary-price">
      {{ getSummaryPrice() | valueWithComma : true }}
      {{ "general.currency" | translate }}
      <a
        class="success-link font-style-light body-text"
        [registerClickEvent]="[
          null,
          'installment',
          'show',
          productFamily.name,
          productFamily.familyCode
        ]"
        (click)="goToInstalmentsSection()"
        >{{ "productCalculator.calcInstallment" | translate }}</a
      >
    </p>
  </div>
</div>

<div
  class="prepared-material"
  *ngIf="activeTab === 'prepared' || hasOnlyUniqeElements(productFamily.products)"
>
  <app-product-variants
    [product]="productFamily"
    [headingTitle]="'Wybierz rozmiar wykładziny'"
    (tooltipInvoked)="showTooltipPopup($event)"
    (selectElement)="addToSelectedElements($event)"
    (statusProductTooltipInvoked)="showProductStatusPopup($event)"
  ></app-product-variants>
</div>
