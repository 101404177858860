<section [ngClass]="{ 'open-from-menu': openedFromMenu }">
  <div
    class="image-wrapper search-icon-vis"
    (click)="openMenuAndFocusOnSearchInput()"
  >
    <i class="ai ai-search icon"></i>
  </div>
  <div
    class="input-search-wrapper"
    [ngClass]="{ open: opened }"
    (click)="updateSearchQuery()"
    sdClickOutside
    (clickOutside)="close($event); loseFocus($event)"
  >
    <input
      appInputClick
      class="input input-search input-search-after is-Empty"
      id="search-input"
      [focusOut]="false"
      autocomplete="off"
      [ngClass]="{
        'separator-one-half': openedFromMenu,
        'label-up': isInputNotEmpty
      }"
      [placeholder]="'find' | translate"
      type="text"
      [id]="'input-search-' + childId"
      [(ngModel)]="query"
      (ngModelChange)="changeDataGuard($event)"
      (click)="open($event)"
      #input
    />
    <label
      class="form-label"
      [ngClass]="{ 'is-Empty': !query || !query.length }"
      for="input-search"
      (click)="open($event)"
      >{{ "find" | translate }}</label
    >
    <button class="ai ai-search" (click)="goSearch()"></button>
    <img #loaderIcon src="/assets/icons/load_anim.svg" />
    <div
      class="search-prompt wider"
      *ngIf="opened && products.length && !disabled"
    >
      <div class="search-prompt-list">
        <div class="product-list">
          <a
            routerLink="/{{ urlTranslateService.routingTable.shop.product }}/{{
              product.productLink
            }}"
            (click)="close(true)"
            class="product-thumb"
            *ngFor="let product of products"
          >
            <div class="product-thumb-container">
              <div class="product-thumb-image">
                <app-img
                  [src]="product.productGroupID"
                  [index]="0"
                  [width]="[30, 30, 30]"
                  [height]="[40, 40, 40]"
                  [options]="{
                    product: true,
                    fillCover: true
                  }"
                ></app-img>
              </div>
              <div
                class="product-desc"
                *ngIf="
                  product.headerStandardPrice &&
                  product.headerStandardPrice == product.headerPromotionPrice
                "
                [innerHTML]="product.highlightedProducName"
              >
              </div>
              <div
                class="product-desc"
                *ngIf="
                  product.headerStandardPrice &&
                  product.headerStandardPrice != product.headerPromotionPrice
                "
                [innerHTML]="product.highlightedProducName"
              >
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div
      class="search-prompt empty"
      *ngIf="opened && searched && !products.length && canShow"
    >
      <div class="search-prompt-header">
        <span>{{ "search.noResult" | translate }}</span>
        <a href="#" class="m-l search-prompt-close" (click)="close($event)">{{
          "search.close" | translate
        }}</a>
      </div>
      <div class="search-prompt-list">
        <p>{{ "search.noResult2" | translate }}</p>
      </div>
    </div>
  </div>
</section>
