import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

export const environment = {
  production: true,
  environment: 'prod',
  baseUrl: window.location.origin + '/',
  contentUrl: 'https://{subdomain-}backend.{domain}.{loc}/',
  apiUrl: 'https://{subdomain-}backend.{domain}.{loc}/',
  //apiUrl: 'https://sgm-backend.arte.pl/',
  blogUrl: 'https://blog.{domain}.{loc}',
  imageUrl: 'https://images.{domain}.{loc}',
  userIdKey: 'predictionUserId',
  tokenKey: 'CustomerKey',
  guidKey: 'GuidKey',
  googleMapAPIKey: 'AIzaSyBd75yw_6KmY-8C6xwnUvqJOmB7d4lbq4w',
  facebookAppId: '726320725570117',
  googlePlusClientId: '6546764459-6j9m497cdm7j4993iuosn0abpn8scger.apps.googleusercontent.com',
  defaultUrlListing: 'a=1&p=1&c=60&q=*',
  instrumentation: {
    output: {
      rollbar: {
        enabled: true,
        accessToken: '184087e27c004dcba44364301f2d9995',
        captureUncaught: true,
        captureUnhandledRejections: true,
        itemsPerMinute: 30,
        maxItems: 100,
        payload: {
          code_version: '24-08-19 07:51',
          environment: 'production',
          source_map_enabled: true,
          guess_uncaught_frames: true,
        }
      }
    }
  },

  analytics: {
    ga4Analytics: true,
    googleAnalytics: true,
    edroneAnalytics: false,
    googleAdsAnalytics: true,
    bingAdsAnalytics: true,
    facebookAnalytics: true,

    Ga4AnalyticsServiceDelay: 1500,
    GoogleAdsAnalyticsServiceDelay: 1,
    EdroneAnalyticsServiceDelay: 3000,
    BingAdsAnalyticsServiceDelay: 1500,
    FacebookAnalyticsServiceDelay: 1500,

    edroneAppID: "63dab09a76d30",
    bingTagID: "211012692",
    facebookPixelID: "686285725215899",


    googleAdsPropertyID: "AW-10936164858",
    googleAdsConversionID: "AW-10936164858/zAjMCMaRicsDEPrD4t4o",
    googleGA4PropertyID: "G-5YVK7HD3DE",
    googleAdsAndGA4: "AdsAndGA4"
  },

  DEFAULT_PRODUCTS_PER_PAGE: 60,
  DEFAULT_PRODUCTS_PER_PAGE_MOBILE: 30,

  instalments: true,
  instalmentsDeley: 200,

  intercom: true,
  IntercomDelay: 3000,

  rollbar: true,
  rollbarDelay: 1500,

  intercomeDataSettings: {
    app_id: 'ffwyw47n',
    user_id: '',
    widget: { 'activator': '#intercom' }
  },

  canBuyWithInstallment: false,
  frontendVersion: 1
};