import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ShopProductFamily } from '@dto';
import { ConfigService } from '@service/config/config.service';
import { environment } from '../../../../../environments/environment';
import { BlockService } from '../../block/shared/block.service';
import { filter } from 'rxjs/operators';
import { Services, Snippet, Snippets } from 'app/model/interfaces';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tile-box',
  templateUrl: './tile-box.component.html',
  styleUrls: ['./tile-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TileBoxComponent
{
  public title: string;
  public description: string;
  public videoLink: SafeResourceUrl;
  private _product: ShopProductFamily;
  public allBlocks = [];
  rowExpanded: boolean = false;
  private _blockName: any;
  public environment = environment;

  @Input() public columns: number = 3;
  @Input() public isProduct: boolean;
  @Input() public showMore: boolean = false;

  get blockName(): any { return this._blockName; }
  @Input() set blockName(value: any)
  {
    this.allBlocks = [];
    this._blockName = value;
    value.forEach(el => this.allBlocks.push(this.getDataSnippet(el)));
  }

  get product(): ShopProductFamily { return this._product; }
  @Input() set product(value: ShopProductFamily)
  {
    this._product = value;
    this.title = value.collectionName;
    this.description = value.collectionDescription;
    this.allBlocks = this.product.parameterDescriptions;

    if (this.product)
    {
      if (this.product.videos)
      {
        if (this.product.videos.filter(o => o.position == "Collection").length > 0)
        {
          this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.product.videos.filter(o => o.position == "Collection")[0].url);
        }
      }
    }
  }

  constructor(private blockService: BlockService, public configService: ConfigService, private sanitizer: DomSanitizer)
  {
  }

  private getDataSnippet(identifier: any): Snippet
  {
    if (!this._product)
    {
      const snippets: Snippets = this.blockService.getDataSync('snippets');
      return snippets.list.filter(el => el.code == identifier)[0];
    }
  }

  public toggleBlocks(status: boolean): void 
  {
    this.rowExpanded = status;
  }
}