import { Injectable } from "@angular/core";
import { environment } from '../../../../../environments/environment';
import
{
  ShopProductFamily,
  ShopProduct,
  Order,
  Cart,
  Wishlist,
  Customer
} from "@dto";
import
{
  SearchResult,
  Record,
} from "@idto";
import { NavigationEnd } from "@angular/router";
import { Ga4AnalyticsService } from "./../ga4-analytics.service";
import { FacebookAnalyticsService } from "./../facebook-analytics.service";
import { AnalyticsInterface } from './analytics.interface';
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { GoogleAdsAnalyticsService } from "./../google-ads-analytics.service";
import { BingAdsAnalyticsService } from "./../bing-ads-analytics.service";
//import { EdroneAnalyticsService } from "./../edrone-analytics.service";
import { AnalyticsBaseService } from "./analytics-base.service";
import { RollbarErrorHandler } from "@service/rollbar/rollbar";
import { catchError, concatMap, defer, delayWhen, EMPTY, map, mergeMap, ReplaySubject, retry, retryWhen, Subject, take, throwError, timer } from "rxjs";

type Dictionary<T> = {
  [key: string]: T;
}
export class Queue
{
  private queue: ReplaySubject<any> = new ReplaySubject<any>();

  constructor(private rollbarService: RollbarErrorHandler)
  {

  }

  public add(func: Function, description: string = "ds"): void
  {
    //let firstPart = description.split(' ')[1];

    this.queue.next({ func: func, description: description });
  }

  public startProcessing(maxRetries = 10, delayMs = 1000): void
  {
    this.queue.pipe(
      concatMap((obj: any) =>
        defer(() => obj.func())
          .pipe(
            retry({ delay: delayMs, count: maxRetries }),
            map(() => obj.description),
            catchError(error =>
            {
              this.rollbarService.handleError(`Processing ${obj.description} failed after ${maxRetries} retries: ${error.message}`);
              return EMPTY;
            })
          )
      )
    ).subscribe({
      // next: result => this.rollbarService.handleInfo(`Processing succeeded: ${JSON.stringify(result)}`),
      error: error => this.rollbarService.handleError(`Processing failed: ${error.message}`)
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements AnalyticsInterface
{
  private analyticsServiceQueues: Dictionary<Queue> = {};
  public analyticsServices = [];

  constructor(
    public ga4AnalyticsService: Ga4AnalyticsService,
    public facebookAnalyticsService: FacebookAnalyticsService,
    public urlTranslateService: UrlTranslateService,
    public googleAdsAnalyticsService: GoogleAdsAnalyticsService,
    public bingAdsAnalyticsService: BingAdsAnalyticsService,
    //public edroneAnalyticsService: EdroneAnalyticsService,
    private rollbarService: RollbarErrorHandler,
  )
  {
    //if (environment.analytics.edroneAnalytics)
    //{
    //this.analyticsServices.push(edroneAnalyticsService);
    //}

    if (environment.analytics.ga4Analytics)
    {
      this.analyticsServices.push(ga4AnalyticsService)
    }

    if (environment.analytics.facebookAnalytics)
    {
      this.analyticsServices.push(facebookAnalyticsService)
    }

    if (environment.analytics.googleAdsAnalytics)
    {
      this.analyticsServices.push(googleAdsAnalyticsService)
    }

    if (environment.analytics.bingAdsAnalytics)
    {
      this.analyticsServices.push(bingAdsAnalyticsService)
    }

    this.analyticsServices.forEach(service =>
    {
      let serviceName = service.getName();
      this.analyticsServiceQueues[serviceName] = new Queue(this.rollbarService);
      this.analyticsServiceQueues[serviceName].add(() =>
      {
        return service.initialize();
      }, serviceName + ' initialize');
    });
  }

  public getName(): string
  {
    return 'AnalyticsService';
  }

  public isEnabled(): boolean
  {
    return true;
  }

  public async initialize(): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      let serviceName = service.getName();
      //var serviceName = service.getName();
      //console.info(serviceName + 'Delay ' + environment.analytics[serviceName + 'Delay'] + ' ' + Date.now());
      setTimeout(() => this.analyticsServiceQueues[serviceName].startProcessing(15, 1000), environment.analytics[serviceName + 'Delay']);
    });
  }

  //#region Generic events
  public async registerEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: any = null, eventNonInteraction: boolean = false): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerEvent(eventCategory, eventAction, eventLabel, eventValue, eventNonInteraction);
      }, service.getName() + ' registerEvent');
    });
  }

  public async registerPageView(event: NavigationEnd): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerPageView(event);
      }, service.getName() + ' registerPageView');
    });

    if (event.urlAfterRedirects == `/${this.urlTranslateService.urlMapping.checkout.login}`)
    {
      return this.registerCheckoutRegistrationEnter();
    }

    if (event.urlAfterRedirects == `/${this.urlTranslateService.urlMapping.checkout.account}`)
    {
      return this.registerCheckoutCustomerDataEnter();
    }

    if (event.urlAfterRedirects == `/${this.urlTranslateService.urlMapping.checkout.summary}`)
    {
      return this.registerCheckoutReviewEnter();
    }

    if (event.urlAfterRedirects.indexOf(`/${this.urlTranslateService.urlMapping.checkout.final}`) >= 0)
    {
      return this.registerCheckoutOrderPlacedEnter();
    }
  }
  //#endregion

  //#region Shop flow events
  public async registerApplicationStart(): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerApplicationStart();
      }, service.getName() + ' registerApplicationStart');
    });
  }

  public async registerProductListView(result: SearchResult, list: string): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerProductListView(result, list);
      }, service.getName() + ' registerProductListView');
    });
  }

  public async registerProductPageView(product: ShopProductFamily): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerProductPageView(product);
      }, service.getName() + ' registerProductPageView');
    });
  }

  public async registerSelectItem(product: Record, list: string): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerSelectItem(product, list);
      }, service.getName() + ' registerSelectItem');
    });
  }

  public async registerWishlistAdd(wishlist: Wishlist, profileFamilySku: string): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerWishlistAdd(wishlist, profileFamilySku);
      }, service.getName() + ' registerWishlistAdd');
    });
  }
  //#endregion

  //#region Transaction
  public async registerCartAdd(product: ShopProduct, quantity: number, cart: Cart): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCartAdd(product, quantity, cart);
      }, service.getName() + ' registerCartAdd');
    });
  }

  public async registerCartRemove(product: ShopProduct, quantity: number, cart: Cart): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCartRemove(product, quantity, cart);
      }, service.getName() + ' registerCartRemove');
    });
  }

  public async registerCoupon(coupon: string): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCoupon(coupon);
      }, service.getName() + ' registerCoupon');
    });
  }

  public async registerCartView(cart: Cart): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCartView(cart);
      }, service.getName() + ' registerCartView');
    });
  }

  public async registerBeginCheckout(cart: Cart): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerBeginCheckout(cart);
      }, service.getName() + ' registerBeginCheckout');
    });
  }

  public async registerTransaction(order: Order): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerTransaction(order);
      }, service.getName() + ' registerTransaction');
    });
  }
  //#endregion

  //#region Basket steps flow
  public async registerCheckoutRegistrationEnter(): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCheckoutRegistrationEnter();
      }, service.getName() + ' registerCheckoutRegistrationEnter');
    });
  }

  public async registerCheckoutCustomerDataEnter(): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCheckoutCustomerDataEnter();
      }, service.getName() + ' registerCheckoutCustomerDataEnter');
    });
  }

  public async registerCheckoutReviewEnter(): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCheckoutReviewEnter();
      }, service.getName() + ' registerCheckoutReviewEnter');
    });
  }

  public async registerCheckoutOrderPlacedEnter(): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCheckoutOrderPlacedEnter();
      }, service.getName() + ' registerCheckoutOrderPlacedEnter');
    });
  }
  //#endregion

  //#region Subscription
  public async registerCustomerNewsletterSubscription(customer: Customer, email: string): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCustomerNewsletterSubscription(customer, email);
      }, service.getName() + ' registerCustomerNewsletterSubscription');
    });
  }
  public async registerCustomerNewsletterUnsubscription(customer: Customer, email: string): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCustomerNewsletterUnsubscription(customer, email);
      }, service.getName() + ' registerCustomerNewsletterUnsubscription');
    });
  }
  //#endregion 

  //#region Customer
  public async registerCustomerRegistration(): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCustomerRegistration();
      }, service.getName() + ' registerCustomerRegistration');
    });
  }
  public async registerCustomerContactRequest(): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerCustomerContactRequest();
      }, service.getName() + ' registerCustomerContactRequest');
    });
  }
  public async registerPosSearch(query: string): Promise<void>
  {
    this.analyticsServices.forEach(service =>
    {
      this.analyticsServiceQueues[service.getName()].add(() =>
      {
        return service.registerPosSearch(query);
      }, service.getName() + ' registerPosSearch');
    });
  }
  //#endregion
}