import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RollbarErrorHandler implements ErrorHandler
{
  private rollbar: any;
  private queue: any[][] = [];
  private reboot: boolean = false;

  constructor()
  {
    if (environment.rollbar)
    {
      setTimeout(() =>
      {
        import('rollbar').then(Rollbar =>
        {
          this.rollbar = new Rollbar.default(environment.instrumentation.output.rollbar);

          this.queue.forEach(element =>
          {
            switch (element[0])
            {
              case 'error': this.handleError(element[1].err); break;
              default: this.handleLog(element[0], element[1].message, element[1].evt); break;
            }
          });
        }).finally(() => 
        {
          if (this.reboot)
          {
            window.location.reload();
          }
        });
      }, environment.rollbarDelay);
    }
    else
    {
      this.rollbar = {
        error: (...args) => { },
        debug: (...args) => { },
        warn: (...args) => { },
        info: (...args) => { },
      };
    }
  }

  public handleError(err: any): void
  {
    console.error(err);

    if (!window.location.host.includes('localhost'))
    {
      if (this.rollbar)
      {
        if (err instanceof HttpErrorResponse)
        {
          this.rollbar.error(err.message, err);
        }
        else
        {
          this.rollbar.error(err.originalError || err);
        }

        if (err.message && (err.message.indexOf('Chunk') > 0 || err.message.indexOf('chunk') > 0)) 
        {
          this.handleInfo('Reload page new version chunk error', err);
          window.location.reload();
        }
      }
      else
      {
        if (err.message && (err.message.indexOf('Chunk') > 0 || err.message.indexOf('chunk') > 0)) 
        {
          this.reboot = true;
        }

        this.queue.push(['error', { err: err }]);
      }
    }
  }

  public handleDebug(message: string, evt?: any): void
  {
    this.handleLog('debug', message, evt);
  }

  public handleWarning(message: string, evt?: any): void
  {
    this.handleLog('warn', message, evt);
  }

  public handleInfo(message: string, evt?: any): void
  {
    this.handleLog('info', message, evt);
  }

  private handleLog(type: 'debug' | 'warn' | 'info', message: string, evt?: any): void
  {
    console[type](message);

    if (window.location.host.includes('localhost'))
    {
      return;
    }

    if (this.rollbar)
    {
      this.rollbar[type](message, evt);
    }
    else
    {
      this.queue.push([type, { message: message, evt: evt }]);
    }
  }
}