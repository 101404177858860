import { Injectable, OnDestroy } from '@angular/core';
import { StorageService } from '@service/storage/storage.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { StringVariable } from '../../../../model/StringVariable.model';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy
{

  private tokenSubject: Subject<string> = new ReplaySubject(1);
  public token$: Observable<string> = this.tokenSubject.asObservable();

  private guidSubject: Subject<string> = new BehaviorSubject(null);
  public guid$: Observable<string> = this.guidSubject.asObservable();

  constructor(private storageService: StorageService)
  {
    this.storageService.storageInit$.subscribe(() =>
    {
      this.storageService.get(environment.tokenKey).subscribe((token: string) =>
      {
        this.tokenSubject.next(token);
      });
      this.storageService.get(environment.guidKey).subscribe((token: string) =>
      {
        this.guidSubject.next(token);
      });
    });
  }

  public setToken(token: string): void
  {
    this.storageService.get(environment.tokenKey).subscribe(res =>
    {
      if (res != token)
      {
        this.storageService.set(environment.tokenKey, token);
        this.tokenSubject.next(token);
      }
    });
  }

  public setGuid(token: string): void
  {
    this.guidSubject.next(token);
  }

  public removeToken(): void
  {
    this.storageService.remove(environment.tokenKey);
    this.storageService.remove(StringVariable.SORTING);
    this.storageService.remove(StringVariable.COMPARE);
    this.tokenSubject.next(null);
  }

  public removeGuid(): void
  {
    this.guidSubject.next(null);
  }

  public ngOnDestroy(): void
  {
    this.tokenSubject.complete();
    this.guidSubject.complete();
  }

  public setStorage(s: string): void
  {
    this.storageService.setStorageSystem(s);
  }

}
