import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { AnalyticsService } from "@service/analytics/abstract/analytics.service";
import { Router } from '@angular/router';

@Directive({
  selector: '[registerClickEvent]'
})
export class RegisterClickEventDirective
{
  @Input() registerClickEvent;

  constructor(private analyticsService: AnalyticsService,
    private router: Router)
  { }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent)
  {
    let tag = (<Element>event.currentTarget).tagName.toLowerCase();

    if (tag == "a" || tag == "span" || tag == "p")
    {
      let anchor = <HTMLAnchorElement>event.currentTarget;

      if (this.registerClickEvent == undefined)
      {
        this.registerClickEvent = [];
      }

      let [location, category, action, targetName, targetValue] = this.registerClickEvent;

      if (location == undefined)
      {
        location = this.router.url;
      }

      if (action == undefined)
      {
        if (anchor.href.indexOf("tel:") >= 0)
        {
          category = "contact";
          action = "call";
        }
        else if (anchor.href.indexOf("mailto:") >= 0)
        {
          category = "contact";
          action = "mail";
        }
        else
        {
          action = "click";
        }
      }

      if (category == undefined)
      {
        category = "generic";
      }

      if (targetName == undefined)
      {
        targetName = anchor.text;
      }

      if (targetValue == undefined)
      {
        targetValue = anchor.pathname.replace(/\s/g, "");
        targetValue = targetValue.replace(/\+/g, "");
      }

      this.analyticsService.registerEvent(category, action, targetName, targetValue);
    }
  }
}
