import { Injectable } from "@angular/core";
import
{
  ShopProductFamily,
  ShopProduct,
  Order,
  Cart,
  Wishlist,
  Customer
} from "@dto";
import
{
  SearchResult,
  Record,
} from "@idto";
import { NavigationEnd } from "@angular/router";
import { AnalyticsBaseService } from "./abstract/analytics-base.service";
import { environment } from "environments/environment";

declare let uetq: any;
declare let UET: any;
@Injectable({
  providedIn: 'root',
})
export class BingAdsAnalyticsService extends AnalyticsBaseService
{
  constructor() { super(); }

  public getName(): string
  {
    return 'BingAdsAnalyticsService';
  }

  public isEnabled()
  {
    return (typeof uetq !== 'undefined');
  }

  public async initialize(): Promise<void>
  {
    if ((window as any).bingScriptPromise == null)
    {
      (window as any).bingScriptPromise = new Promise<void>((resolve, reject) =>
      {
        window["uetq"] = [];

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.onload = () =>
        {
          var o = { ti: environment.analytics.bingTagID, q: [] };
          window["uetq"] = new UET(o);
          window["uetq"].push("pageLoad");
          resolve();
        }
        script.onerror = () => reject(new Error('Failed to load uetq script'));
        script.src = 'https://bat.bing.com/bat.js';

        document.getElementsByTagName('body')[0].appendChild(script);
      });
    }

    return (window as any).bingScriptPromise;
  }


  //#region Generic events
  // public async registerEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: any = null, eventNonInteraction: boolean = false): Promise<void>
  // {
  // }

  public async registerPageView(event: NavigationEnd): Promise<void>
  {
    if (event.urlAfterRedirects == "/")
    {
      uetq.push('event', '', { 'ecomm_pagetype': 'home' });
    }
  }
  //#endregion

  //#region Shop flow events 
  public async registerProductListView(result: SearchResult, list: string): Promise<void>
  {

    const items = result.records.map(item => { return item.productGroupID; });

    uetq.push('event', '', {
      'ecomm_category': result.searchQuery.uRL,
      'ecomm_prodid': items,
      'ecomm_pagetype': 'category'
    });



    //   const items = result.records.map(item => { return item.productGroupID; });

    //   uetq.push('event', '', {
    //     'ecomm_category': result.searchQuery.uRL,
    //     'ecomm_prodid': items,
    //     'ecomm_pagetype': 'category'
    //   });
  }

  public async registerProductPageView(product: ShopProductFamily): Promise<void>
  {
    uetq.push('event', '', {
      'ecomm_prodid': [product.familyCode],
      'ecomm_pagetype': 'product'
    });
  }

  // public async registerSelectItem(product: Record, list: string): Promise<void>
  // {
  // }

  // public async registerWishlistAdd(wishlist: Wishlist, profileFamilySku: string): Promise<void>
  // {
  // }
  //#endregion

  //#region Transaction
  public async registerCartAdd(product: ShopProduct, quantity: number, cart: Cart): Promise<void>
  {
    uetq.push('event', 'add_to_cart',
      {
        'ecomm_prodid': [product.familyCode],
        'ecomm_pagetype': 'product',
        'ecomm_totalvalue': (product.promotionPrice * quantity) / 1.23,
        'revenue_value': (product.promotionPrice * quantity) / 1.23,
        'currency': 'PLN',
        'items': [
          {
            'id': 'product.familyCode',
            'quantity': quantity,
            'price': product.promotionPrice
          }
        ]
      }
    );
  }

  public async registerCartView(cart: Cart): Promise<void>
  {
    let subItems = [];
    const itemWrappers = cart.items.map(item =>
    {
      if (item.subItems && item.subItems.length)
      {
        item.subItems.map(o =>
        {
          if (o.active)
          {
            subItems.push(o)
          }
        });
      }

      return { id: item.product.familyCode, quantity: item.quantity, price: item.unitPromotionPriceNetto }
    });

    const subItemWrappers = subItems.map(item =>
    {
      return { id: item.product.familyCode, quantity: item.quantity, price: item.unitPromotionPriceNetto }
    });

    const allItemWrappers = itemWrappers.concat(subItemWrappers);
    const allItemIDs = allItemWrappers.map(item => item.id);

    uetq.push('event', '', {
      'ecomm_prodid': allItemIDs,
      'ecomm_pagetype': 'cart',
      'ecomm_totalvalue': cart.grandPromotionTotalNetto,
      'revenue_value': cart.grandPromotionTotalNetto,
      'currency': 'PLN',
      'items': allItemWrappers
    }
    );
  }


  // public async registerBeginCheckout(cart: Cart): Promise<void>
  // {
  // }

  public async registerTransaction(order: Order): Promise<void>
  {
    let subItems = [];
    const itemWrappers = order.items.map(item =>
    {
      if (item.subItems && item.subItems.length)
      {
        item.subItems.map(o =>
        {
          subItems.push(o);
        });
      }

      return { id: item.product.familyCode, quantity: item.quantity, price: item.unitDiscountedPriceBrutto }
    });

    const subItemWrappers = subItems.map(item =>
    {
      return { id: item.product.familyCode, quantity: item.quantity, price: item.unitDiscountedPriceBrutto }
    });

    const allItemWrappers = itemWrappers.concat(subItemWrappers);
    const allItemIDs = allItemWrappers.map(item => item.id);


    uetq.push('event', 'purchase', {
      'transaction_id': order.iD,
      'ecomm_prodid': allItemIDs,
      'ecomm_pagetype': 'purchase',
      'ecomm_totalvalue': (order.productsTotalBrutto / 1.23).toFixed(2),
      'revenue_value': (order.productsTotalBrutto / 1.23).toFixed(2),
      'currency': 'PLN',
      'items': allItemWrappers
    });
  }
  //#endregion

  //#region Basket steps flow
  // public async registerCheckoutRegistrationEnter(): Promise<void>
  // {
  // }

  // public async registerCheckoutCustomerDataEnter(): Promise<void>
  // {
  // }

  // public async registerCheckoutReviewEnter(): Promise<void>
  // {
  // }

  // public async registerCheckoutOrderPlacedEnter(): Promise<void>
  // {
  // }
  //#endregion

  //#region Subscription
  // public async registerCustomerNewsletterSubscription(): Promise<void>
  // {
  // }
  // public async registerCustomerNewsletterUnsubscription(): Promise<void>
  // {
  // }
  //#endregion
}