import { Injectable, OnDestroy } from '@angular/core';
import { Cart, CartItem, CartItemAutoOptionsDTO, CartResultDTO, CartUpdateDTO, Order, OrderResultDTO, ShopProduct } from '@dto';
import { AnalyticsService } from "@service/analytics/abstract/analytics.service";
import { getCamelCaseVariableName } from 'app/utils/utilsFunctions';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { AuthHttpService } from '../http/auth-http/auth-http.service';
import { TimestampService } from '../timestamp/timestamp.service';

@Injectable({
  providedIn: 'root'
})
export class CartService implements OnDestroy
{
  private cartSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public readonly cart$: Observable<Cart> = this.cartSubject.asObservable();
  public widgetVisibility: Subject<boolean> = new Subject();
  private resetSelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public readonly resetSelected$: Observable<boolean> = this.resetSelectedSubject.asObservable();

  private apiUrl: string;

  constructor(private authHttpService: AuthHttpService,
    private timestampService: TimestampService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService)
  {

    this.apiUrl = configService.ApiUrl + 'cart/swiatdywanowshop/cart';
  }

  public updateCartSubject(cartData): void
  {
    this.cartSubject.next(cartData);
    this.timestampService.update();
  }

  public resetSelected(): void
  {
    this.resetSelectedSubject.next(true);
  }

  public getShipmentDate(): string
  {
    const temp = this.cartSubject.getValue();
    if (temp && temp.shippingTime)
    {
      return temp.shippingTime.daysTimeLabelShort;
    }
    return '';
  }

  public addElementToCart(fatherPositionId: any, product: ShopProduct, quantity: number): Observable<Cart>
  {
    if (!product)
    {
      return;
    }
    return this.authHttpService.put(this.apiUrl + `/item/${fatherPositionId}/${product.iD}/${quantity.toFixed(2)}`, null).pipe(
      map((res: CartResultDTO) =>
      {
        const cart = getCamelCaseVariableName(res, true).cart;
        this.updateCartSubject(cart);
        this.widgetVisibility.next(true);
        return cart;
      }),
      tap(res => this.analyticsService.registerCartAdd(product, quantity, res)));
  }

  public cartAddElementToCartWithBinding(fatherPositionId: any, product: ShopProduct, quantity: number, bindingAutosizeOption: number, binding: number): Observable<Cart>
  {
    return this.authHttpService.put(this.apiUrl + `/item/${fatherPositionId}/${product.iD}/${quantity}/${bindingAutosizeOption}/${binding}`).pipe(
      map((res: CartResultDTO) => getCamelCaseVariableName(res, true).cart),
      tap((res: any) => this.updateCartSubject(res)),
      tap((res: any) => this.analyticsService.registerCartAdd(product, quantity, res)));
  }



  public cartPositionToggle(fatherPositionId: any, productId: any, active: boolean): Observable<Cart>
  {
    return this.authHttpService.post(this.apiUrl + `/item/${fatherPositionId}/${productId}/option/toggle/${active}`, null).pipe(
      map((res: CartResultDTO) => getCamelCaseVariableName(res, true).cart),
      tap((res: any) =>
      {
        this.updateCartSubject(res);
        this.widgetVisibility.next(true);
      }));
  }

  public cartPositionSwitch(fatherPositionId: any, productId: any, newProductID: number): Observable<Cart>
  {
    return this.authHttpService.post(this.apiUrl + `/item/${fatherPositionId}/${productId}/option/switch/${newProductID}`, null).pipe(
      map((res: CartResultDTO) => getCamelCaseVariableName(res, true).cart),
      tap((res: any) =>
      {
        this.updateCartSubject(res);
        this.widgetVisibility.next(true);
      }));
  }

  public setNumberOfElementInCart(fatherPositionId: any, positionId: number, quantity: number): Observable<Cart>
  {
    return this.authHttpService.post(this.apiUrl + `/item/${fatherPositionId}/${positionId}/${quantity}`, null).pipe(
      map((res: CartResultDTO) => getCamelCaseVariableName(res, true).cart),
      tap((res: any) =>
      {
        this.updateCartSubject(res);
        this.widgetVisibility.next(true);
      }));
  }

  public cartBindingChange(fatherPositionId: any, positionId: any, dto: CartItemAutoOptionsDTO): Observable<Cart>
  {
    return this.authHttpService.post(this.apiUrl + `/item/${fatherPositionId}/${positionId}/autosizeoptions`, dto).pipe(
      map((res: CartResultDTO) => getCamelCaseVariableName(res, true).cart),
      tap((res: any) => this.updateCartSubject(res)));
  }

  public removeElementFromCart(fatherPositionId: any, positionId: any): Observable<Cart>
  {
    const removedProduct = this.getCartItemByPositionID(positionId);

    return this.authHttpService.delete(this.apiUrl + `/item/${fatherPositionId}/${positionId}`)
      .pipe(
        map((res: CartResultDTO) => getCamelCaseVariableName(res, true).cart),
        tap((res: any) => this.updateCartSubject(res)),
        tap((res: any) => this.analyticsService.registerCartRemove(removedProduct.product, removedProduct.quantity, res))
      );
  }

  public removeProductFromCart(fatherPositionId: any, shopProduct: ShopProduct): Observable<Cart>
  {
    const removedProduct = this.getCartItemByProductID(shopProduct.iD);

    return this.authHttpService.delete(this.apiUrl + `/item/${fatherPositionId}/${removedProduct.iD}`)
      .pipe(
        map((res: CartResultDTO) => getCamelCaseVariableName(res, true).cart),
        tap((res: any) => this.updateCartSubject(res)),
        tap((res: any) => this.analyticsService.registerCartRemove(shopProduct, removedProduct.quantity, res))
      );
  }

  public updateCart(updateCartDto: CartUpdateDTO): Observable<Cart>
  {
    return this.authHttpService.post(this.apiUrl, updateCartDto).pipe(
      map((res: CartResultDTO) => getCamelCaseVariableName(res, true).cart),
      tap((res: any) => this.updateCartSubject(res)));
  }

  public addCoupon(couponCode: string): Observable<Cart>
  {
    return this.authHttpService.post(this.apiUrl + `/coupon/${couponCode}`).pipe(
      map(res => getCamelCaseVariableName(res, true).cart),
      tap((res: any) => this.updateCartSubject(res)));
  }

  public setPaymentMethodCode(paymentMethodCode: any): Observable<Cart>
  {
    return this.authHttpService.post(this.apiUrl + `/payment/${paymentMethodCode}`).pipe(
      map((res: CartResultDTO) => getCamelCaseVariableName(res, true).cart),
      tap((res: any) => this.updateCartSubject(res)));
  }

  public setDeliveryMethodCode(deliveryMethodCode): Observable<Cart>
  {
    return this.authHttpService.post(this.apiUrl + `/delivery/${deliveryMethodCode}`).pipe(
      map((res: CartResultDTO) =>
      {
        const cart = getCamelCaseVariableName(res, true).cart;
        this.updateCartSubject(cart);
        return cart;
      }));

  }

  public cartSubmit(grandTotal: number): Observable<Order>
  {
    return this.authHttpService.put(this.apiUrl, { 'GrandTotal': parseFloat(grandTotal.toFixed(2)) }).pipe(map(res => getCamelCaseVariableName(res, true).order),
      tap(res => this.analyticsService.registerTransaction(res)));
  }

  public ngOnDestroy(): void
  {
    this.cartSubject.complete();
    this.widgetVisibility.complete();
  }

  public getDeliveryMethod(): string
  {
    if (this.cartSubject.value)
    {
      return this.cartSubject.value.selectedDeliveryMethod.code;
    }
  }

  public getPaymentMethod(): string
  {
    if (this.cartSubject.value)
    {
      return this.cartSubject.value.selectedPaymentMethod.code;
    }
  }

  public getCartItemByPositionID(positionID: number): CartItem
  {
    return this.cartSubject.value && this.cartSubject.value.items.filter(item => item.iD === positionID)[0];
  }

  public getCartItemByProductID(productId: any): CartItem
  {
    return this.cartSubject.value && this.cartSubject.value.items.filter(item => item.product.iD === productId)[0];
  }
}
