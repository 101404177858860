<div class="popup-wrapper" [hidden]="!shouldShow">
  <div class="upper-section">
    <div class="elem-list-wrapper">
      <i class="ai ai-advisor"></i>
      <a
        class="success-link font-style-light"
        [registerClickEvent]="[
          null,
          'contact',
          'open',
          'requestCall',
          'requestCall'
        ]"
        (click)="openAdvisorPopup($event)"
        >{{ "modalsTexts.orderPhoneFromConsultant" | translate }}</a
      >
    </div>
    <div class="elem-list-wrapper">
      <i class="ai ai-txt-cloud"></i>
      <a
        class="success-link font-style-light"
        [registerClickEvent]="[
          null,
          'contact',
          'open',
          'requestChat',
          'requestChat'
        ]"
        (click)="showIntercom()"
        >{{ "modalsTexts.chatConsultant" | translate }}</a
      >
    </div>
    <div class="elem-list-wrapper">
      <i class="ai ai-write-form"></i>
      <a
        class="success-link font-style-light"
        [registerClickEvent]="[
          null,
          'contact',
          'open',
          'sendMessageForm',
          'sendMessageForm'
        ]"
        (click)="openContactForm($event)"
        >{{ "modalsTexts.writeToUs" | translate }}</a
      >
    </div>
  </div>
  <div class="separator-gray"></div>
  <div class="lower-section" *ngIf="contact">
    <div class="elem-list-wrapper no-margin">
      <i class="ai ai-phone"></i>
      <div>
        <div class="font-style-semi heading5">
          <a
            [registerClickEvent]
            [href]="'tel:' + contact.ecommerce.primaryPhoneNumber | trim"
            >{{ contact.ecommerce.primaryPhoneNumber }}</a
          >
          <span *ngIf="contact.ecommerce.secondaryPhoneNumber"> / </span>
          <a
            *ngIf="contact.ecommerce.secondaryPhoneNumber"
            [registerClickEvent]
            [href]="'tel:' + contact.ecommerce.secondaryPhoneNumber | trim"
            >{{ contact.ecommerce.secondaryPhoneNumber }}</a
          >
        </div>
        <ng-container *ngIf="!workdayHoursSameAsSaturday">
          <p class="font-style-light">
            {{ "contact.mon-fri" | translate }}:
            {{ contact.openHours.weekday.from }} -
            {{ contact.openHours.weekday.to }}
          </p>
          <p class="font-style-light">
            {{ "contact.sat" | translate }}: {{ contact.openHours.saturday.from }} -
            {{ contact.openHours.saturday.to }}
          </p>
        </ng-container>
        <ng-container *ngIf="workdayHoursSameAsSaturday">
          <p class="font-style-light">
            {{ "contact.mon-sat" | translate }}:
            {{ contact.openHours.weekday.from }} -
            {{ contact.openHours.weekday.to }}
          </p>
        </ng-container>
      </div>
    </div>
    <div class="elem-list-wrapper">
      <i class="ai ai-mail"></i>
      <span class="font-style-light"
        >e-mail:
        <a
          class="success-link"
          [registerClickEvent]
          href="mailto:sklep@arte.pl"
          (click)="hide()"
          >sklep&#64;arte.pl</a
        ></span
      >
    </div>
    <div class="elem-list-wrapper">
      <i class="ai ai-order"></i>
      <a
        class="success-link font-style-light"
        [routerLink]="'/' + urlTranslateService.routingTable.order"
        (click)="hide()"
        >{{ "modalsTexts.checkOrderStatus" | translate }}</a
      >
    </div>
  </div>
  <button
    class="button secondary-button font-style-semi"
    [routerLink]="'/' + urlTranslateService.routingTable.additional.contact"
    (click)="hide()"
  >
    {{ "modalsTexts.goToContactPage" | translate }}
  </button>
</div>
<app-advisor-popup
  name="advisor"
  (closeHover)="hide()"
  [mainTitle]="'modalsTexts.orderPhoneFromConsultant' | translate"
  [secondaryTitle]="'modalsTexts.giveUsPhoneAndWaitForConsultant' | translate"
  [defaultButtonText]="'modalsTexts.orderPhone' | translate"
  [showWhen]="true"
  [showPhone]="true"
  [isAdvisorPopup]="true"
  [phoneRequired]="true"
></app-advisor-popup>

<app-contact-form-popup
  name="contact-form"
  (closeHover)="hide()"
  [mainTitle]="'modalsTexts.contactForm' | translate"
  [secondaryTitle]="'modalsTexts.contactFormDiscuss' | translate"
  [defaultButtonText]="'sendMessage' | translate"
  [isContactFormPopup]="true"
  [showPhone]="true"
  [showName]="true"
  [showEmail]="true"
  [showMessage]="true"
  [showOptionalDataCaption]="true"
  [phonePlaceholder]="'modalsTexts.phonePlaceholder' | translate"
>
</app-contact-form-popup>
