<div
  class="calculator-wrapper"
  #calculatorWrapper
  [ngClass]="{ 'non-sticky': !shouldWrapperBeStickyField }"
>
  <div [ngClass]="{ archived: product.isArchived }">
    <!--  todo translate 300 zł-->
    <div class="flag flag-success" *ngIf="!isOutlet && !product.isCovering && !product.isWalkway">
      {{ "cart.freeShipmentFrom" | translate }} 300
      {{ "general.currency" | translate }}
    </div>
    <div class="flag flag-error" *ngIf="isOutlet">Outlet</div>
    <app-product-variants
      *ngIf="showSizeSelection"
      [product]="product"
      [headingTitle]="headerTitle"
      (tooltipInvoked)="showTooltipPopup($event, product)"
      (selectElement)="updateLocalCart($event)"
      (statusProductTooltipInvoked)="showStatusProductTooltip($event)"
    ></app-product-variants>
    <app-product-size-calculator
      *ngIf="showSizeCalculator"
      [productFamily]="product"
      (tooltipInvoked)="showTooltipPopup($event)"
      (selectElement)="updateLocalCart($event)"
      (resetList)="resetSelectedList()"
      (binding)="setBinding($event)"
      (bindingAll)="setBindingAll($event)"
      (statusProductTooltipInvoked)="showStatusProductTooltip($event)"
      (summaryPrice)="onSummaryPriceChange($event)"
    >
    </app-product-size-calculator>
    <app-alert-bar
      class="calculator-error-bar"
      *ngIf="error"
      [errorText]="error.message"
      [alertStatus]="error.type ? error.type : 'Error'"
      (publicCloseBar)="closeError()"
    ></app-alert-bar>
    <div class="button-wrapper">
      <button
        class="button primary-button"
        [disabled]="isLoading"
        (click)="addToCart()"
        #addButton
      >
        <ng-container *ngIf="!isLoading">{{
          "cart.addToCart" | translate
        }}</ng-container>
        <span></span>
      </button>
      <div
        [ngClass]="{
          circle: isLessThanDesktopResolution(),
          'added-to-fav': isInFav
        }"
        class="button tertiary-button"
        (click)="addToFav()"
      >
        <span>{{
          isInFav
            ? ("favourite.inFavourite" | translate)
            : ("favourite.addToFavourite" | translate)
        }}</span>
        <i class="ai ai-heart" [ngClass]="{'active': isInFav}"></i>
      </div>
    </div>
    <div class="links-wrapper">
      <div class="raty" *ngIf="translateService.currentLang === 'pl' || canBuyWithInstallment">
        <p *ngIf="!product.isCovering" class="font-style-light raty-description">
          {{ "cart.canBuyWithInstallment" | translate }}.
          <a class="success-link" [registerClickEvent]="[ null, 'installment', 'show', product.name, product.familyCode ]" (click)="goToInstalmentsSection()">
            {{ "productCalculator.calcInstallment" | translate }}
          </a>
        </p>
        <p *ngIf="product.isCovering" class="font-style-light raty-description">
          {{ "cart.cannotDecide" | translate }}
          <a class="success-link free-sample-wrapper" [registerClickEvent]="[ null, 'freeSample', 'add', product.name, product.familyCode ]" (click)="goToFreeSamplePopup($event, product)" >
             {{ "modalsTexts.orderFreeSample" | translate }}
          </a>
        </p>
        <app-alert-bar
          class="calculator-error-bar"
          *ngIf="installmentError"
          [errorText]="installmentError.message"
          [alertStatus]="
            installmentError.type ? installmentError.type : 'Error'
          "
          (publicCloseBar)="closeError('installmentError')"
        ></app-alert-bar>
      </div>
      <p
        *ngIf="isShopAvilabilityActive"
        class="success-link relative"
        [registerClickEvent]="[
          null,
          'shopAvilability',
          'show',
          product.name,
          product.familyCode
        ]"
        (click)="goToProductStatusPopup($event, product)"
      >
        <i class="ai ai-pin"></i>
        <span class="font-style-light">{{ "cart.checkAvailabilityInLocalShop" | translate }}</span>
      </p>
      <p
        class="success-link"
        [registerClickEvent]="[
          null,
          'chat',
          'open',
          product.name,
          product.familyCode
        ]"
        (click)="showIntercom()"
      >
        <i class="ai ai-txt-cloud"></i>
        <span class="font-style-light">{{ "cart.askAboutProduct" | translate }}</span>
      </p>
    </div>
  </div>
  <app-alert-bar
    *ngIf="product.isArchived"
    class="archived-error"
    [errorText]="'error.archivedProduct' | translate"
    [alertStatus]="'Error'"
    [cannotClose]="true"
  ></app-alert-bar>
</div>

<app-free-sample-popup
  name="sample"
  [mainTitle]="'cart.freeSample' | translate"
  buttonType="primary-button"
  [defaultButtonText]="'cart.addToCart' | translate"
  [product]="product"
>
</app-free-sample-popup>
<app-product-availability-popup
  name="availability"
  [mainTitle]="'cart.reserveProduct' | translate"
  [dropdownOptions]="shopList"
  buttonType="primary-button"
  [defaultButtonText]="'cart.reservingProduct' | translate"
  [navigationBarTitle]="'cart.checkAvailability' | translate"
  [product]="product"
  [showName]="true"
  [showEmail]="true"
  [showPhone]="true"
  [verticalSeparatorGrey]="true"
  (successEmitter)="showSuccessBar($event)"
  [secondaryTitle]="product.name"
>
</app-product-availability-popup>

<app-product-status-popup
  name="status-3"
  [mainTitle]="'cart.reserveProduct' | translate"
  [dropdownOptions]="shopList"
  [product]="product"
  [pictureAltName]="product.name"
  [defaultButtonText]="'cart.reserveProduct' | translate"
  [buttonType]="'primary-button'"
  [secondaryTitle]="product.name"
  [showEmail]="true"
  [showName]="true"
  [showPhone]="true"
  (successEmitter)="showSuccessBar($event)"
>
</app-product-status-popup>

<app-tooltip-popup
  name="delivery"
  [mainTitle]="'tooltip.unknownDate' | translate"
  [tooltip]="true"
  [buttonType]="'primary-button'"
  [product]="product"
  [defaultButtonText]="'tooltip.callMeWhenAvailable' | translate"
  (successEmitter)="showSuccessBar($event)"
>
</app-tooltip-popup>
<app-product-add-item-popup
  [product]="product"
  name="add-item"
></app-product-add-item-popup>
