import
{
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UrlHelperService } from '@service/helpers/url-helper.service';
import { RollbarErrorHandler } from '@service/rollbar/rollbar';
import { SearchRepositoryService } from '@service/search-repository/search-repository.service';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { getImageUrl, getTextWidth } from '../../../../utils/utilsFunctions';
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { SuggestResult } from '@idto';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy
{
  public getImageUrl = getImageUrl;
  @Input() public openedFromMenu: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public childId: string;
  @Output() public readonly openedEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly openMenuAndFocusOnSearchInputEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly searchingInProgress: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('input', { static: true }) public input: ElementRef;
  @ViewChild('loaderIcon', { static: true }) public loaderIcon: ElementRef;
  public query: string;
  public opened = false;
  public searched = false;
  public products: any = [];
  private numberOfItems: number = 10;
  private destroy$: Subject<void> = new Subject();
  private suggestIsEmpty = true;
  public isInputNotEmpty: boolean = false;
  private updateTimeout: any;
  public canShow: boolean;
  public thousendValue: any;

  constructor(
    private router: Router,
    private renderer2: Renderer2,
    private rollbarService: RollbarErrorHandler,
    private changeDet: ChangeDetectorRef,
    private urlHelperService: UrlHelperService,
    private searchRepoService: SearchRepositoryService,
    private activatedRoute: ActivatedRoute,
    public urlTranslateService: UrlTranslateService
  )
  { }

  public ngOnInit(): void
  {
    // todo: naprawic to ze 2 razy nie mozna tej samej frazy wpisać
    const query = this.activatedRoute.snapshot.queryParams['q'];
    if (query && query !== '*')
    {
      this.query = decodeURI(query);
      this.isInputNotEmpty = true;
    }

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd), takeUntil(this.destroy$)).subscribe((event: NavigationEnd) =>
    {
      const params = this.urlHelperService.parseQueryStringToObject(event.url);
      if (params['q'] && params['q'] !== '*')
      {
        this.query = decodeURI(params['q']);
      }
    });
    this.getSuggest();
  }

  public isDecodedUrl(str: string): boolean
  {
    return str.indexOf('%') != -1;
  }

  public ngAfterViewInit(): void
  {
    if (this.query != '')
    {
      this.renderer2.removeClass(this.input.nativeElement, 'ng-pristine');
      this.renderer2.addClass(this.input.nativeElement, 'ng-dirty');
    }
  }

  public ngOnDestroy(): void
  {
    if (this.changeDet && !this.changeDet['destroyed'])
    {
      this.destroy$.next();
      this.destroy$.complete();
    }
  }

  public changeDataGuard(event: string): void
  {
    this.openedEmitter.emit(true);
    let query = encodeURIComponent(event);

    if (query?.length == 0)
    {
      this.products = [];
      this.canShow = false;
      this.openedEmitter.emit(true);
      this.searchRepoService.lastSearch = '';
      this.searchRepoService.updateQuery(query);
      this.changeDet.detectChanges();
    }

    this.suggestIsEmpty = true;

    if (query)
    {
      this.canShow = true;
      this.searchRepoService.updateQuery(query);
    }
  }


  public open($event?: any): boolean
  {
    this.opened = true;
    this.searched = false;
    this.renderer2.removeClass(this.loaderIcon.nativeElement, 'loading');
    setTimeout(() => this.input.nativeElement.focus());
    this.openedEmitter.emit(true);
    this.changeDet.detectChanges();
    return false;
  }

  public close(b?: any): boolean
  {
    if (b && b.target && this.opened)
    {
      if (b.target.className.indexOf('form-label') == 0 || b.target.className.indexOf('hamburger') >= 0)
      {
        return false;
      }
    }
    this.opened = false;
    this.searched = false;
    this.canShow = false;
    this.openedEmitter.emit(false);
    if (b == true)
    {
      this.searchingInProgress.emit(false);
    }
    this.changeDet.detectChanges();
    return false;
  }

  @HostListener('document:keyup', ['$event'])
  public handleKeyboardEvent(event: any): void
  {
    if (event.target.placeholder == 'Szukaj')
    {
      this.opened = true;
    }
    if (this.opened)
    {
      if (event.key === 'Escape')
      {
        this.close();
      } else if (event.key === 'Enter')
      {
        if (event.target.value != this.query)
        {
          return;
        }
        this.submit();
      }
    } else
    {
      this.updateSearchQuery();
    }
  }

  @HostListener('window:mousedown', ['$event'])
  public onmouseDown(event: any): void
  {
    if (event && event.target && event.target.id && event.target.id.includes('input input-search'))
    {
      this.open();
    }
  }

  @HostListener('window:keyup', ['$event'])
  public onKeydownHandler(event: KeyboardEvent): void
  {
    if (event.keyCode === 13)
    {
      this.input.nativeElement.blur();
    }
  }


  public updateSearchQuery(): void
  {
    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() =>
    {
      if (this.query && this.opened)
      {
        this.searchRepoService.updateQuery(this.query);
      }
      if (this.query == '')
      {
        this.products = [];
        this.searchRepoService.lastSearch = '';
        this.searched = false;
      }
      this.changeDet.detectChanges();
    }, 144);
  }

  public submit(): boolean
  {
    if (this.opened && this.query)
    {
      this.router.navigate([`/${this.urlTranslateService.routingTable.shop.listing}`], { queryParams: { q: this.query } });
      this.close();
      this.searchingInProgress.emit(true);
    } else
    {
      this.open();
    }
    return false;
  }

  public setSearchInputAsFocused(): void
  {
    setTimeout(() =>
    {
      this.renderer2.removeClass(this.input.nativeElement, 'is-Empty');
      this.input.nativeElement.focus();
    }, 50);
  }

  public openMenuAndFocusOnSearchInput(): void
  {
    if (!this.changeDet['destroyed'])
    {
      this.openMenuAndFocusOnSearchInputEmitter.emit();
    }
  }

  private getSuggest(): void
  {
    this.searchRepoService.query$.pipe(debounceTime(500), distinctUntilChanged(), switchMap((q: string) =>
    {
      this.renderer2.setStyle(this.loaderIcon.nativeElement, 'left', 50 + getTextWidth(this.input.nativeElement.value, '16px Flexobold') + 'px');
      this.renderer2.addClass(this.loaderIcon.nativeElement, 'loading');

      if (this.opened && q.length)
      {
        return this.searchRepoService.search(q, this.numberOfItems);
      }
      return of([]);
    }), takeUntil(this.destroy$)).subscribe((results: SuggestResult) =>
    {
      if (results instanceof Array)
      {
        this.searched = true;
        this.suggestIsEmpty = true;
        this.canShow = false;
        this.products = [];
      }
      else
      {
        this.searched = true;
        this.canShow = true;
        this.suggestIsEmpty = results.records.length === 0;

        if (results.records.length)
        {
          this.parseResults(results);
        }
        else
        {
          this.products = [];
        }
      }
      this.renderer2.removeClass(this.loaderIcon.nativeElement, 'loading');
      this.changeDet.detectChanges();
    });
  }

  private parseResults(results: SuggestResult): void
  {
    this.searched = true;
    this.products = results.records.map((record) =>
    {
      if (this.query)
      {
        const expression = new RegExp(this.query, 'gi');
        record.highlightedProducName = record.productName.replace(expression, '<strong class="match">$&</strong>');
      }

      return record;
    });
    this.changeDet.detectChanges();
  }

  public loseFocus($event: any): void
  {
    if (!($event.target.className instanceof Object))
    {
      if (!$event.target.className.includes('form-label is-empty') && !$event.target.className.includes('input-search') && (this.input.nativeElement.value == '' || this.input.nativeElement.value == null))
      {
        this.renderer2.removeClass(this.input.nativeElement, 'label-up');
        this.input.nativeElement.blur();
      }
    }
    this.changeDet.detectChanges();
  }

  public goSearch(): void
  {
    this.submit();
  }
}
