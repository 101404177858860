import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FamilyType, ProductFamilyGetDTO, SearchResultDTO, SuggestResult, SuggestResultDTO } from '@idto';
import { ShopProduct, ShopProductFamily } from '@dto';
import { UrlHelperService } from '@service/helpers/url-helper.service';
import { AuthService } from '@service/session/auth/auth.service';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, mergeMap, take, map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { AuthHttpService } from '../http/auth-http/auth-http.service';
import { HttpService } from '../http/http.service';
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { getCamelCaseVariableName } from 'app/utils/utilsFunctions';

enum Paths
{
  PRODUCT = 'product/swiatdywanowshop/productfamily',
  SEARCH_MENU = 'menu',
  SEARCH_SEARCH = 'search',
  SEARCH_SUGGEST = 'suggest'
}

@Injectable({
  providedIn: 'root'
})
export class SearchRepositoryService implements OnDestroy
{
  private searchVisibleSubject: Subject<boolean> = new Subject<boolean>();
  public readonly searchVisible$: Observable<boolean> = this.searchVisibleSubject.asObservable();
  private queryChangeSubject: Subject<string> = new Subject<string>();
  public readonly query$: Observable<string> = this.queryChangeSubject.asObservable();
  private menuCategoriesData: any;
  public lastSearch: string;
  private dataIsLoaded: Subject<any> = new Subject<any>();
  private bestSellerLink: string = 'p=1&c=60&ut=token&type=bestseller';
  private token: string;
  public loading: Subject<boolean> = new BehaviorSubject(false);

  constructor(
    private httpService: HttpService,
    private configService: ConfigService,
    private authService: AuthService,
    private authHttpService: AuthHttpService,
    public urlTranslateService: UrlTranslateService
  )
  {
    this.authService.token$.subscribe(res =>
    {
      this.bestSellerLink = `${this.urlTranslateService.routingTable.shop.listing}?` + this.bestSellerLink.replace('token', res);
      this.token = res;
      this.loading.next(true);
    });
  }

  public setSearchVisible(b: boolean): void
  {
    this.searchVisibleSubject.next(b);
  }

  public getProduct(url: string, recommendationID?: string): Observable<ShopProductFamily>
  {
    return this.loading.pipe(filter(v => v === true), take(1), mergeMap(() => this.authHttpService.post(this.configService.ApiUrl + Paths.PRODUCT, {
      productFamilyCode: url,
      recommendationID: recommendationID,
      customerAuthorizationToken: this.token
    } as ProductFamilyGetDTO).pipe(map(res =>
    {
      let initialParse: ShopProductFamily = getCamelCaseVariableName(res, true);

      initialParse.isCarpet = initialParse.familyType === FamilyType.DYWANY;
      initialParse.isWalkway = initialParse.familyType === FamilyType.CHODNIKI;
      initialParse.isWiper = initialParse.familyType === FamilyType.AKCESORIA_WYCIERACZKI;
      initialParse.isCovering = initialParse.familyType === FamilyType.WYKLADZINA_DYWANOWA || initialParse.familyType === FamilyType.WYKLADZINA_ELASTYCZNA || initialParse.familyType === FamilyType.TRAWA;

      initialParse.products?.forEach((product: ShopProduct) =>
      {
        product.isCarpet = product.productType === FamilyType.DYWANY;
        product.isWalkway = product.productType === FamilyType.CHODNIKI;
        product.isWiper = product.productType === FamilyType.AKCESORIA_WYCIERACZKI;
        product.isCovering = product.productType === FamilyType.WYKLADZINA_DYWANOWA || product.productType === FamilyType.WYKLADZINA_ELASTYCZNA || initialParse.familyType === FamilyType.TRAWA;
      });

      initialParse.accessoryProducts?.forEach((product: ShopProduct) =>
      {
        product.isCarpet = product.productType === FamilyType.DYWANY;
        product.isWalkway = product.productType === FamilyType.CHODNIKI;
        product.isWiper = product.productType === FamilyType.AKCESORIA_WYCIERACZKI;
        product.isCovering = product.productType === FamilyType.WYKLADZINA_DYWANOWA || product.productType === FamilyType.WYKLADZINA_ELASTYCZNA || initialParse.familyType === FamilyType.TRAWA;
      });

      return initialParse;
    }))));
  }

  public fetch(): Observable<SearchResultDTO>
  {
    return this.httpService.get(this.configService.ApiUrl + 'search/swiatdywanowsearch/' + Paths.SEARCH_SEARCH).pipe(map((res: SearchResultDTO) => getCamelCaseVariableName(res, true)));
  }

  public fetchByQuery(query: string): Observable<SearchResultDTO>
  {
    return this.httpService.get(this.configService.ApiUrl + 'search/swiatdywanowsearch/' + Paths.SEARCH_SEARCH + '?' + query).pipe(map((res: SearchResultDTO) => getCamelCaseVariableName(res, true)));
  }

  public getSuggestByQuery(query: string): Observable<SuggestResult>
  {
    return this.httpService.get(this.configService.ApiUrl + 'search/swiatdywanowsearch/' + Paths.SEARCH_SUGGEST + '?' + query).pipe(map((res: any) => getCamelCaseVariableName(res, true)));
  }

  public updateQuery(value): void
  {
    this.queryChangeSubject.next(value);
  }

  public ngOnDestroy(): void
  {
    this.queryChangeSubject.complete();
  }

  public search(query: string, limit: number = 0): Observable<SuggestResult>
  {
    let urlQuery = 'q=' + encodeURIComponent(query);

    if (limit)
    {
      urlQuery += '&c=' + encodeURIComponent(limit);
    }

    if (query != this.lastSearch)
    {
      this.lastSearch = query;

      if (query != '')
      {
        return this.getSuggestByQuery(urlQuery).pipe(map((res) =>
        {
          this.dataIsLoaded.next({ records: res.records });
          return res;
        }));
      }
    }
    else
    {
      return this.dataIsLoaded.pipe(map((res) =>
      {
        return res;
      }));
    }
  }

  public resetSearchInput(): void
  {
    this.updateQuery('');
  }

  public getProductDeliveryDate(product: ShopProductFamily, calculateSize: number): Observable<string>
  {
    return of('5 dni');
  }

  public getBestsellerLink(): string
  {
    return this.bestSellerLink;
  }
}
