import { Injectable } from "@angular/core";
import
{
  ShopProductFamily,
  ShopProduct,
  Order,
  Cart,
  Wishlist,
  Customer
} from "@dto";
import
{
  SearchResult,
  Record,
} from "@idto";
import { environment } from "environments/environment";
import { AnalyticsBaseService } from "./abstract/analytics-base.service";

declare let fbq: Function;
declare let fbqScriptPromise: Promise<void>;
declare let initFbq: Function;
@Injectable({
  providedIn: 'root'
})
export class FacebookAnalyticsService extends AnalyticsBaseService
{
  //#region Basic
  constructor()
  {
    super();
  }

  public getName(): string
  {
    return 'FacebookAnalyticsService';
  }

  public isEnabled()
  {
    return (typeof fbq !== 'undefined');
  }
  //#endregion

  public async initialize(): Promise<void>
  {
    if ((window as any).fbqScriptPromise == null)
    {
      let n = (window as any).fbq = function ()
      {
        if (n['callMethod'])
        {
          //console.info('n[callMethod].apply(n, arguments);');
          n['callMethod'].apply(n, arguments);
        }
        else
        {
          //console.info('n[queue].push(arguments);');
          n['queue'].push(arguments);
        }
      } as any;

      (window as any)._fbq = n;

      n['push'] = n;
      n['loaded'] = !0;
      n['version'] = "2.0";
      n['queue'] = [];

      (window as any).fbqScriptPromise = new Promise<void>((resolve, reject) =>
      {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.onload = () =>
        {
          fbq("init", environment.analytics.facebookPixelID);
          fbq("track", "PageView");
          resolve();
        }
        script.onerror = () => reject(new Error('Failed to load fbq script'));
        script.src = 'https://connect.facebook.net/en_US/fbevents.js';

        document.getElementsByTagName('body')[0].appendChild(script);
      });
    }

    return (window as any).fbqScriptPromise;
  }

  //#region Generic events
  public async registerEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: any = null, eventNonInteraction: boolean = false): Promise<void>
  {
    fbq('trackCustom', eventCategory + '/' + eventAction, { eventLabel: eventLabel, eventValue: eventValue });
  }
  //#endregion

  //#region Shop flow events
  public async registerProductListView(result: SearchResult, list: string): Promise<void>
  {
    fbq('track', 'ViewContent',
      {
        contents: result.records.map(function (d) { return { id: d.productGroupID, name: d.productName } }),
        content_type: "product_group",
      });
  }

  public async registerProductPageView(product: ShopProductFamily): Promise<void>
  {
    const familyCode = [{ id: product.familyCode, name: product.name }];
    const allCodes = familyCode.concat(product.products.map(function (d) { return { id: d.code, name: d.name } }));

    fbq('track', 'ViewContent',
      {
        contents: allCodes,
        content_type: "product",
      });
  }

  public async registerWishlistAdd(wishlist: Wishlist, profileFamilySkuL: string): Promise<void>
  {
    fbq('track', 'AddToWishlist',
      {
        contents: [{ id: profileFamilySkuL, quantity: 1 }],
        content_type: "product"
      });
  }
  //#endregion

  //#region Transaction
  public async registerCartAdd(product: ShopProduct, quantity: number, cart: Cart): Promise<void>
  {
    fbq('track', 'AddToCart',
      {
        contents: [{ id: product.code, quantity: quantity, name: product.name }],
        content_type: "product",
        currency: "PLN",
        value: (product.promotionPrice / 1.23) * quantity,
      });
  }

  public async registerCoupon(coupon: string): Promise<void>
  {
    fbq('trackCustom', 'Coupon', { coupon: coupon });
  }

  public async registerCartRemove(product: ShopProduct, quantity: number, cart: Cart): Promise<void>
  {
    fbq('trackCustom', 'RemoveFromCart', {
      contents: [{ id: product.code, quantity: quantity, name: product.name }],
      content_type: "product",
      currency: "PLN",
      value: (product.promotionPrice / 1.23) * quantity,
    });
  }

  public async registerCartView(cart: Cart): Promise<void>
  {
    fbq('track', 'InitiateCheckout',
      {
        currency: "PLN",
        value: cart.grandPromotionTotalNetto,
        contents: cart.items.map(function (o) { return { id: o.product.code, quantity: o.quantity } }),
        content_type: 'product'
      });
  }

  public async registerBeginCheckout(cart: Cart): Promise<void>
  {
    fbq('trackCustom', 'CheckoutSecondStep',
      {
        currency: "PLN",
        value: cart.grandPromotionTotalNetto,
        contents: cart.items.map(function (o) { return { id: o.product.code, quantity: o.quantity } }),
        content_type: 'product'
      });
  }

  public async registerTransaction(order: Order): Promise<void>
  {
    let coupon = "";

    if (order.discounts && order.discounts.length > 0)
    {
      coupon = order.discounts[0].couponCode;
    }

    fbq('track', 'Purchase',
      {
        currency: "PLN",
        value: (order.productsTotalBrutto / 1.23),
        coupon: coupon,
        contents: order.items.map(function (o) { return { id: o.product.code, quantity: o.quantity } }),
        content_type: 'product'
      });
  }
  //#endregion

  //#region Subscription
  public async registerCustomerNewsletterSubscription(): Promise<void>
  {
    fbq('track', 'Subscribe', {});
  }
  public async registerCustomerNewsletterUnsubscription(): Promise<void>
  {
    fbq('trackCustom', 'Unsubscribe', {});
  }
  //#endregion

  //#region Customer
  public async registerCustomerRegistration(): Promise<void>
  {
    fbq('track', 'CompleteRegistration', {});
  }
  public async registerCustomerContactRequest(): Promise<void>
  {
    fbq('track', 'Contact', {});
  }
  public async registerPosSearch(query: string): Promise<void>
  {
    fbq('track', 'FindLocation', { query: query });
  }
  //#endregion
}